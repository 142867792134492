<template>
  <div class="st-option" data-t="st-option" @click="selectHandler">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import type { BaseSelectApi } from '../types'

const props = defineProps<{
  id: number | string
  disabled?: boolean
}>()

const selectApi: BaseSelectApi | undefined = inject('StBaseSelectApi')

function selectHandler() {
  if (props.disabled) return

  selectApi?.selectItemHandler(props.id)
}
</script>

<style scoped>
.st-option {
  cursor: pointer;
  display: flex;
}
</style>
