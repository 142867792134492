<template>
  <div ref="stBaseSelect" class="st-base-select" data-t="st-base-select">
    <slot name="field" :is-expanded="isExpanded"></slot>
    <div class="separator"></div>

    <div class="base-dropdown">
      <StDrawer v-if="hasDrawer" v-model="isExpanded" :full-screen="false">
        <StDrawerHeader :title="mobileTitle" disable-router-back />
        <div class="options-wrapper mobile-drawer">
          <slot> </slot>
        </div>
      </StDrawer>
      <StTransitionExpand v-else>
        <div v-if="isExpanded">
          <div class="options-wrapper">
            <slot> </slot>
          </div>
        </div>
      </StTransitionExpand>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@st/use/composables'
import type { BaseSelectApi } from './types'

const props = withDefaults(
  defineProps<{
    modelValue?: string | number
    hasDrawer?: boolean
    isDisabled?: boolean
    mobileTitle?: string
  }>(),
  {
    isDisabled: false,
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string): void
  (e: 'onSelectItem', value: any): void
}>()

const isExpanded = ref(false)

const stBaseSelect = ref<HTMLDivElement>()
onClickOutside(stBaseSelect, () => {
  isExpanded.value = false
})

function selectItemHandler(id: string | number) {
  isExpanded.value = false

  emit('update:modelValue', id)
}

function toggleDropdown() {
  if (props.isDisabled) return

  isExpanded.value = !isExpanded.value
}

const api: BaseSelectApi = {
  selectItemHandler,
  toggleDropdown,
  isExpanded: computed(() => isExpanded.value),
}

provide('StBaseSelectApi', api)
</script>

<style scoped>
.st-base-select {
  position: relative;
  width: 100%;
}

.base-dropdown {
  position: absolute;
  z-index: 999;

  overflow: auto;

  width: max-content;
  min-width: 100%;
  max-height: 400px;
  margin-top: var(--spacing-100);

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-100);
  box-shadow:
    0 8px 12px -4px rgb(0 0 0 / 32%),
    0 16px 24px -4px rgb(0 0 0 / 24%);
}

.options-wrapper {
  padding: var(--spacing-050);
}

.mobile-drawer {
  overflow: auto;
  padding-bottom: var(--spacing-250);
}
</style>
