<template>
  <div class="st-select-field" data-t="st-select-field" @click="toggle">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import type { BaseSelectApi } from '../types'

const props = withDefaults(
  defineProps<{ isDefaultClickDisabled?: boolean }>(),
  {
    isDefaultClickDisabled: false,
  },
)

const selectApi: BaseSelectApi | undefined = inject('StBaseSelectApi')

function toggle() {
  if (props.isDefaultClickDisabled) return

  selectApi?.toggleDropdown()
}
</script>

<style scoped>
.st-select-field {
  display: flex;
  width: 100%;
}
</style>
