<template>
  <StOption :id="item.id" :disabled="item.disabled">
    <div
      :data-t="`item-${item.id}`"
      class="option"
      :class="wrapperClasses"
      role="option"
      :aria-disabled="item.disabled"
      @click="emit('optionClicked', item.id)"
    >
      <div class="options-content">
        <StIcon
          v-if="item.icon"
          :name="item.icon"
          :size="props.size === 's' ? 16 : 20"
          class="icon"
          data-t="option-icon"
        />
        <div
          v-if="item.title || item.subtitle || item.caption"
          class="description"
        >
          <div v-if="item.title || item.subtitle" class="title-section">
            <div v-if="item.title" class="title" data-t="option-title">
              {{ item.title }}
            </div>
            <div v-if="item.subtitle" class="subtitle" data-t="option-subtitle">
              {{ item.subtitle }}
            </div>
            <div v-if="item.label" class="label">
              {{ item.label }}
            </div>
          </div>
          <div v-if="item.caption" class="caption" data-t="option-caption">
            {{ item.caption }}
          </div>
        </div>
        <div v-if="item.value || item.subValue" class="amount">
          <div v-if="item.value" class="top" data-t="option-value">
            {{ item.value }}
          </div>
          <div v-if="item.subValue" class="bottom" data-t="option-subvalue">
            {{ item.subValue }}
          </div>
        </div>
      </div>
      <div v-if="!withoutStatusIcon" class="option-icon">
        <StIcon
          v-if="item.disabled"
          name="lock-solid"
          :size="size === 'm' || platform === 'mobile' ? 20 : 16"
        />
        <StIcon
          v-else-if="isSelected"
          class="selected"
          name="check"
          :size="size === 'm' || platform === 'mobile' ? 20 : 16"
        />
      </div>
    </div>
  </StOption>
</template>

<script setup lang="ts">
import StOption from '@st/ui/components/StBaseSelect/parts/StOption.vue'
import type { SelectItem } from './types'

const props = withDefaults(
  defineProps<{
    item: SelectItem
    size: 'm' | 's'
    platform: 'desktop' | 'mobile'
    isSelected: boolean
    disabled: boolean
    withoutStatusIcon?: boolean
  }>(),
  { size: 'm', isSelected: false, platform: 'desktop', disabled: false },
)

const emit = defineEmits<{
  (e: 'optionClicked', id: number | string): void
}>()

const wrapperClasses = computed(() => [
  `size-${props.size}`,
  `platform-${props.platform}`,
  props.item.disabled ? 'disabled' : '',
])

const lineWidth = computed(() => {
  if (props.item.icon) {
    return 'calc(100% - 46px)'
  }

  return 'calc(100% - 12px)'
})
</script>

<style scoped>
.option {
  position: relative;

  display: flex;
  gap: var(--spacing-125, 4px);
  align-items: center;

  width: 100%;
  max-height: 48px;
  padding: var(--spacing-150) 0 var(--spacing-150) var(--spacing-150);

  &:hover {
    background-color: var(--background-tertiary);
    border-radius: var(--border-radius-075);
  }

  &.disabled {
    opacity: var(--disable-layer-opasity, 0.4);
  }
}

.options-content {
  display: flex;
  gap: var(--spacing-150);
  align-items: center;
  justify-content: flex-start;

  width: 100%;
}

.title-section {
  display: flex;
  gap: var(--spacing-100);
  align-items: baseline;

  .title {
    font: var(--desktop-text-md-semibold);
  }

  .subtitle {
    font: var(--desktop-text-md-medium);
    color: var(--palette-light-600);
  }
}

.label {
  padding: var(--spacing-025) var(--spacing-050);

  font: var(--desktop-text-xs-medium);
  color: var(--text-success);

  background-color: #536463; /* stylelint-disable-line */
  border-radius: var(--border-radius-075);
}

.caption {
  font: var(--desktop-text-xs-medium);
  color: var(--palette-light-600);
}

.amount {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
}

.top {
  font: var(--desktop-text-md-semibold);
}

.bottom {
  font: var(--desktop-text-xs-medium);
  color: var(--palette-light-600);
}

.option-icon {
  display: flex;
  justify-content: flex-end;

  min-width: var(--spacing-400);
  padding: 0 var(--spacing-100);

  text-align: center;
}

.selected {
  color: var(--system-success);
}

.option.size-s {
  padding: var(--spacing-100) 0 var(--spacing-100) var(--spacing-100);

  .title {
    font: var(--desktop-text-xs-semibold);
  }

  .top {
    font: var(--desktop-text-xs-medium);
  }

  .subtitle {
    font: var(--desktop-text-xs-medium);
  }
}

.option.platform-mobile {
  gap: var(--spacing-050);
  min-height: var(--spacing-600);
  padding: var(--spacing-100) var(--spacing-100) var(--spacing-100)
    var(--spacing-200);

  &::after {
    content: '';

    position: absolute;
    right: 0;
    bottom: 0;

    width: v-bind(lineWidth);

    border-bottom: 1px solid var(--border-primary);
  }

  .title-section {
    display: flex;
    gap: var(--spacing-075);
  }

  .option-icon {
    padding: 0 var(--spacing-050);
  }

  .title {
    font: var(--mobile-text-medium);
  }

  .subtitle {
    font: var(--mobile-text-medium);
  }

  .top {
    margin-bottom: var(--spacing-025);
    font: var(--mobile-text-medium);
  }

  .bottom {
    font: var(--mobile-caption-1-regular);
  }
}
</style>
