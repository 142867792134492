<template>
  <div class="network-select" data-t="network-select">
    <StBaseSelect
      v-model="model"
      :has-drawer="platform === 'mobile'"
      :is-disabled="isDisabled"
      :mobile-title="t('payments.deposit.chooseNetwork')"
    >
      <template #field="data">
        <PaymentsSelectField
          :placeholder="t('payments.deposit.chooseNetwork')"
          :label="t('payments.deposit.network')"
          :icon-postfix="iconPostfix(data)"
          :first-value="selectedItem?.title"
          :second-value="selectedItem?.subtitle"
          :platform="platform"
        />
      </template>
      <PaymentsSelectOption
        v-for="account in formattedNetworks"
        :id="account.id"
        :key="account.id"
        :item="account"
        :platform="platform"
        :is-selected="selectedItem?.id === account.id"
      />
    </StBaseSelect>
  </div>
</template>

<script setup lang="ts">
import type { Network } from '../../interfaces'

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number | undefined): void
}>()

const props = withDefaults(
  defineProps<{
    modelValue: string | number | undefined
    networks?: Network[]
    platform?: 'desktop' | 'mobile'
    operationType?: 'deposit' | 'withdrawal' | 'address'
    allowedIds?: number[]
    isDisabled?: boolean
  }>(),
  {
    platform: 'desktop',
    operationType: 'address',
    isDisabled: false,
  },
)

const model = computed({
  get() {
    return props.modelValue
  },
  set(value: string | number | undefined) {
    emit('update:modelValue', value)
  },
})

const { t } = useI18n()

function isDisabledNetwork(item: Network) {
  switch (props.operationType) {
    case 'deposit': {
      return !item.allowedForDeposit
    }

    case 'withdrawal': {
      return !item.allowedForWithdrawal
    }

    case 'address': {
      return false
    }

    default: {
      return false
    }
  }
}

const formattedNetworks = computed(() =>
  (props.networks || [])
    .filter(
      (network) => !props.allowedIds || props.allowedIds.includes(network.id),
    )
    .map((network) => ({
      id: network.id,
      title: network.description,
      subtitle: network.name,
      disabled: isDisabledNetwork(network),
    })),
)

const selectedItem = computed(() =>
  formattedNetworks.value.find((account) => account.id === props.modelValue),
)

const iconPostfix = computed(() => (data: { isExpanded: boolean }) => {
  if (props.isDisabled) return 'lock-solid'

  return data.isExpanded ? 'chevron-top' : 'chevron-down'
})
</script>

<style scoped>
.network-select {
  display: flex;
}
</style>
