<template>
  <div class="currency-select" data-t="currency-select">
    <StBaseSelect
      v-model="model"
      :has-drawer="platform === 'mobile'"
      :is-disabled="isDisabled"
      :mobile-title="t('payments.deposit.chooseCurrency')"
    >
      <template #field="data">
        <PaymentsSelectField
          :placeholder="t('payments.deposit.chooseCurrency')"
          :label="t('payments.deposit.currency')"
          :icon-prefix="selectedItem?.icon"
          :icon-postfix="iconPostfix(data)"
          :first-value="selectedItem?.title"
          :second-value="selectedItem?.subtitle"
          :third-value="selectedItem?.value"
          :platform="platform"
        />
      </template>
      <div class="options-title" :class="platform">
        <div class="title left">
          {{ t('payments.deposit.currency') }}
        </div>
        <div class="title right">
          {{ t('payments.deposit.myBalance') }}
        </div>
      </div>
      <PaymentsSelectOption
        v-for="account in mappedAccounts"
        :id="account.id"
        :key="account.id"
        :item="account"
        :platform="platform"
        :is-selected="selectedItem?.id === account.id"
      />
    </StBaseSelect>
  </div>
</template>

<script setup lang="ts">
import { useAccountsStore } from '../../stores/useAccountsStore'
import { useCurrenciesStore } from '../../stores/useCurrenciesStore'
import PaymentsSelectField from '../PaymentsSelectField/PaymentsSelectField.vue'
import PaymentsSelectOption from '../PaymentsSelectOption/PaymentsSelectOption.vue'

const currenciesStore = useCurrenciesStore()
const { appCurrency } = storeToRefs(currenciesStore)

const accountsStore = useAccountsStore()
const { realAccounts } = storeToRefs(accountsStore)

const { format: formatCurrency } = useCurrencyFormatter({
  currency: appCurrency.value.code,
})
const { format: formatCrypto } = useCryptoFormatter()
const { t } = useI18n()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number | undefined): void
}>()

const props = withDefaults(
  defineProps<{
    modelValue: string | number | undefined
    platform: 'mobile' | 'desktop'
    operationType?: 'deposit' | 'withdrawal' | 'address'
    allowedIds?: number[]
    isDisabled?: boolean
  }>(),
  {
    platform: 'desktop',
    operationType: 'address',
    isDisabled: false,
  },
)

const model = computed({
  get() {
    return props.modelValue
  },
  set(value: string | number | undefined) {
    emit('update:modelValue', value)
  },
})

function isDisabledCurrency(item: (typeof realAccounts.value)[0]) {
  switch (props.operationType) {
    case 'deposit': {
      return !item.allowedForDeposit
    }

    case 'withdrawal': {
      return !item.allowedForWithdrawal
    }

    case 'address': {
      return false
    }

    default: {
      return false
    }
  }
}

const iconPostfix = computed(() => (data: { isExpanded: boolean }) => {
  if (props.isDisabled) return 'lock-solid'

  return data.isExpanded ? 'chevron-top' : 'chevron-down'
})

const mappedAccounts = computed(() =>
  realAccounts.value
    .filter(
      (item) => !props.allowedIds || props.allowedIds.includes(item.currencyId),
    )
    .map((item) => ({
      id: item.currencyId,
      title: item.code,
      subtitle: item.name,
      icon: item.icon,
      disabled: isDisabledCurrency(item),
      value: formatCrypto(item.balance),
      subValue: `~${formatCurrency(item.fiatBalance)}`,
    })),
)

const selectedItem = computed(() =>
  mappedAccounts.value.find((account) => account.id === props.modelValue),
)
</script>

<style scoped>
.currency-select {
  display: flex;
}

.options-title {
  display: flex;
  gap: var(--spacing-150);
  justify-content: space-between;

  margin-bottom: var(--spacing-025);
  padding: var(--spacing-050) var(--spacing-200);

  font: var(--desktop-text-xs-medium);
  color: var(--palette-light-600);

  border-bottom: 1px solid var(--border-secondary);

  .title {
    padding: var(--spacing-100) 0;
  }

  .right {
    margin-right: var(--spacing-400);
  }
}

.options-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-100) var(--spacing-100) var(--spacing-100)
    var(--spacing-150);
}

.header-text {
  font: var(--mobile-title-2-semibold);
}

.options-title.mobile {
  padding: var(--spacing-050) var(--spacing-200);
  border-bottom: none;

  .title {
    padding: 0;
  }

  .right {
    margin-right: var(--spacing-300);
  }
}
</style>
